





















import Vue from 'vue'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from 'vuex'
import CHybridTelephone from '~/components/shared/configurable/telephone/CHybridTelephone.vue'

export default Vue.extend({
  components: { CHybridTelephone },
  props: {
    telephone: {
      type: Object,
      required: true
    },
    extraTelephone: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true
    },
    recordClickStatForClassifiedId: {
      type: [Number, String],
      default: null
    }
  },
  data: () => ({
    icons: {
      phone: faPhone
    }
  }),
  computed: {
    ...mapGetters({
      sellerIsDealerOrAgent: 'classifieds/view/sellerIsDealerOrAgent'
    })
  }
})
