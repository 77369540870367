































































































































































import Vue from 'vue'
import {
  faInfo,
  faPaperPlane,
  faPhone,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import CClassifiedSellerCardTelephone from '~/components/shared/configurable/classified/seller/CClassifiedSellerCardTelephone.vue'
import {
  sellerIsBlacklistedAsSuspiciousOrFraudster,
  sellerIsOfTypeDealer
} from '~/utils/user'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import UserProfileLink from '~/components/car/user/UserProfileLink.vue'
import { mapGetters, mapState } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { getTelephoneCode } from '~/utils/telephone'
import { APP_NS } from '~/store/modules/shared/app/state'
import { CLASSIFIED_VIEW_NS } from '~/store/modules/shared/classifieds/view/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'

export default Vue.extend({
  components: {
    CClassifiedSellerCardTelephone,
    CAvatar,
    UserProfileLink,
    Contact: () =>
      import('~/components/car/classifieds/view/partials/Contact.vue'),
    CContactForm: () =>
      import('~/components/shared/configurable/CContactForm.vue'),
    CAnonPrompt: () =>
      import('~/components/shared/configurable/user/CAnonPrompt.vue'),
    PrivateSellerBadge: () =>
      import('~/components/shared/classified/view/PrivateSellerBadge.vue'),
    DealerBadge: () =>
      import('~/components/car/classifieds/view/DealerBadge.vue'),
    AgentBadge: () =>
      import('~/components/plot/classifieds/view/AgentBadge.vue'),
    ConstructorBadge: () =>
      import('~/components/plot/classifieds/view/ConstructorBadge.vue'),
    BlacklistedSellerAlert: () =>
      import('~/components/car/classifieds/view/BlacklistedSellerAlert.vue')
  },
  props: {
    seller: {
      type: Object,
      required: true
    },
    fallbackAvatar: {
      type: String,
      default: null
    },
    goToUserProfile: {
      type: Boolean,
      default: true
    },
    noAvatar: {
      type: Boolean,
      required: false,
      default: false
    },
    showMessageButton: {
      type: Boolean,
      default: false
    },
    recordClickStatForClassifiedId: {
      type: [Number, String],
      default: null
    },
    classified: {
      type: Object,
      required: false,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      isFormVisible: false,
      isContactFormLoading: false
    }
  },
  computed: {
    ...mapState(USER_NS, {
      userId: state => state.id
    }),
    ...mapGetters(USER_NS, {
      isAdmin: 'isAdmin'
    }),
    ...mapState(CLASSIFIED_VIEW_NS, [
      'contactFormExtraText',
      'showContactPrompt'
    ]),
    ...mapGetters(CLASSIFIED_VIEW_NS, {
      sellerOnlyContactViaMessages: 'sellerOnlyContactViaMessages',
      isPlotClassified: 'isPlotClassified',
      isRentalClassified: 'isRentalClassified',
      sellerIsConstructionCompany: 'sellerIsConstructionCompany'
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      inPlotSearch: 'inPlotSearch'
    }),
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    shouldShowMessageButton() {
      return (
        this.showMessageButton &&
        this.seller.canReceiveMessages &&
        !this.isPlot &&
        !this.sellerIsSuspiciousOrFraudster &&
        !this.isRentalClassified
      )
    },
    isOwn() {
      return (
        this.seller.id === this.userId || this.seller.manager_id === this.userId
      )
    },
    icons() {
      return {
        info: faInfo,
        user: faUser,
        phone: faPhone,
        message: faPaperPlane
      }
    },
    extraTelephoneCode() {
      const { extra_telephone: extraTelephone } = this.seller
      if (!extraTelephone) {
        return
      }
      return getTelephoneCode(extraTelephone, this.$i18n.locale)
    },
    userProfileUrl() {
      return this.seller.profile_url
    },
    showTelephones() {
      return (
        (!this.sellerIsSuspiciousOrFraudster &&
          !this.sellerOnlyContactViaMessages) ||
        this.isAdmin
      )
    },
    sellerIsSuspiciousOrFraudster() {
      return sellerIsBlacklistedAsSuspiciousOrFraudster(this.seller)
    },
    sellerIsDealer() {
      return sellerIsOfTypeDealer(this.seller)
    },
    url(): string {
      let sellerType = 'dealers'
      if (this.isPlot || this.inPlotSearch) {
        sellerType = 'agents'
      }
      return `/api/${sellerType}/${this.seller.id}/contact/`
    }
  },
  methods: {
    handleShowForm() {
      this.isContactFormLoading = true
      this.isFormVisible = true
    }
  }
})
